import { featureFlagEnabled } from 'common/utils/helpers';

function setStorageVariables() {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('embed')) return;

  sessionStorage.setItem('embed', '1');

  if (params.has('origin') && params.has('iframe')) {
    sessionStorage.setItem('embedOrigin', params.get('origin'));
    sessionStorage.setItem('embedIframe', params.get('iframe'));
  }
}

function setDocumentClass() {
  if (sessionStorage.getItem('embed') === null) return;
  document.documentElement.classList.add('embed');
}

/**
 * @param {boolean} once
 */
function setupHeightUpdates(once) {
  const origin = sessionStorage.getItem('embedOrigin');
  const iframe = sessionStorage.getItem('embedIframe');
  if (origin === null || iframe === null) return;

  const defaultData = {
    name: 'documentHeightChange',
    documentHeight: 400,
    iframe,
  };

  const sendHeight = () => {
    const data = {
      ...defaultData,
      documentHeight: document.documentElement.getBoundingClientRect().height,
    };

    window.parent.postMessage(data, origin);
  };

  const observer = new ResizeObserver(sendHeight);

  window.addEventListener('beforeunload', () => {
    observer.disconnect();
    window.parent.postMessage(defaultData, origin);
  });

  if (once) sendHeight();
  else observer.observe(document.documentElement);
}

/**
 * @param {boolean} once
 */
export function setupEmbeddedContent(once) {
  setStorageVariables();
  setDocumentClass();
  setupHeightUpdates(once);
}

/**
 * @param {string} url
 */
export function getEmbedSnippet(url) {
  return `<div class="zencity-engage-embed" data-embed-url="${url}"></div>
<script>
  !function(e,t,r){if(!e.zcEngageEmbed){var i=e.zcEngageEmbed=t.createElement(r);
  i.src="${window.location.origin}/packs/embed.js",i.defer=!0;
  var n=t.getElementsByTagName(r)[0];n.parentNode.insertBefore(i,n)}}
  (window,document,"script");
</script>`;
}

/**
 * Get the widget snippet
 * @param {string} dataName
 * @param {string | number} id
 * @returns
 */
export function getWidgetSnippet(dataName, id, language = 'en') {
  return `<div class='civilspace-widget editor' ${dataName}='${id}' data-language='${language}'></div>
<script>
gon={};gon.featureFlagPublishUI=${featureFlagEnabled('publishing_ui')};
</script>
<script>
  !function(e,t,r){if(!e.cswidget){var i=e.cswidget=t.createElement(r);
  i.src="${window.location.origin}/packs/widget.js",i.defer=!0;
  var n=t.getElementsByTagName(r)[0];n.parentNode.insertBefore(i,n)}}
  (window,document,"script");
</script>`;
}

/**
 * Get the engagement widget snippet
 * @param {string | number} engagementId
 * @returns string
 */
export function getEngagementWidgetSnippet(engagementId, language) {
  return getWidgetSnippet('data-engagement-id', engagementId, language);
}
